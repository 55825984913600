import { addPauseListenerToVideo } from "./Millicast_function";
class Player {
    constructor(props) {
        this.player = props.player;
        this.iconPlay = '<span class="material-icons"> play_arrow </span>';
        this.buttonContainer = document.createElement('div');
    }

    renderPlayBigButton() {
        if (!document.querySelector('.button-container')) {
            this.buttonContainer.classList.add('button-container');

            let button = document.createElement('button');
            button.classList.add('btn', 'btn-primary', 'btn-play');
            button.innerHTML = this.iconPlay;
            button.addEventListener('click', () => this.initPlay())

            this.buttonContainer.appendChild(button);
            this.player.parentElement.appendChild(this.buttonContainer);
        }
    }

    initPlay() {
        this.play();
        this.showControls();
        this.buttonContainer.remove();
    }

    play() {
        this.player.play();
        addPauseListenerToVideo(this.player);
    }

    pause() {
        this.player.pause();
    }

    showControls() {
        this.player.setAttribute('controls', 'controls');
    }

    isPaused() {
        return this.player.paused;
    }


}

export {
    Player
}